import { toast } from 'react-toastify';

/**
 * Executa uma requisição de API e lida com sucesso ou erro.
 * @param {Function} action - Método de chamada à API (obrigatório).
 * @param {string} msgSuccess - Mensagem de sucesso a ser exibida (opcional).
 * @param {string} msgError - Mensagem de erro a ser exibida (opcional).
 * @param {Function} setIsLoading - Função para controlar o estado de carregamento (opcional).
 * @param {Function} triggerRefresh - Função para atualizar a lista após sucesso (opcional).
 * @param {Function} callback - Função de callback após execução bem-sucedida (opcional).
 */
export const executeRequest = async ({
  action,
  msgSuccess = '',
  msgError = '',
  setIsLoading = () => {},
  triggerRefresh = () => {},
  callback = () => {},
}) => {
  setIsLoading(true);

  try {
    const response = await action();

    if (isSuccessfulResponse(response)) {
      handleSuccess(msgSuccess, triggerRefresh, callback);
      setIsLoading(false);
      return response;
    }

    throw new Error('Erro na operação');
  } catch (error) {
    handleError(msgError, error);
    throw error;
  } finally {
    setIsLoading(false);
  }
};

/**
 * Verifica se a resposta da API é considerada bem-sucedida.
 * @param {object} response - A resposta da API.
 * @returns {boolean} - Retorna true se a resposta for bem-sucedida.
 */
const isSuccessfulResponse = (response) => {
  const hasStatusSuccess = response?.status === 204 || (response?.status >= 200 && response?.status < 300);
  return response || hasStatusSuccess;
};

/**
 * Lida com o sucesso da requisição.
 * @param {string} msgSuccess - Mensagem de sucesso.
 * @param {Function} triggerRefresh - Função para atualizar a lista após sucesso.
 * @param {Function} callback - Função de callback após sucesso.
 */
const handleSuccess = (msgSuccess, triggerRefresh, callback) => {
  if (msgSuccess) {
    toast.success(msgSuccess);
  }
  triggerRefresh?.();
  callback?.();
};

/**
 * Lida com erros da requisição.
 * @param {string} msgError - Mensagem de erro.
 * @param {object} error - O erro capturado.
 */
const handleError = (msgError, error) => {
  if (msgError) {
    toast.error(msgError);
  }
  console.error('Erro durante a requisição:', error.message || error);
};
