import { Field } from 'formik';

import { FormControl, FormLabel, Select } from '@chakra-ui/react';

/**
 * Renderiza um campo de seleção (Select) dinâmico para formulários com Formik.
 *
 * @param {Object} options - Opções para renderização do campo.
 * @param {string} options.name - O nome do campo.
 * @param {string} options.placeholder - Placeholder para o campo.
 * @param {Array}  options.list - Lista de opções para o select.
 * @param {string} options.keyProp - A chave que será usada como a chave única (key) para cada opção.
 * @param {string} options.displayProp - A chave que será exibida como o valor da opção.
 * @param {Object} options.props - Outras propriedades que podem ser passadas para o campo Select.
 */

export const renderSelectField = ({ name, placeholder, list = [], keyProp, displayProp, ...props }) => (
  <FormControl>
    <FormLabel>{placeholder}</FormLabel>

    <Field name={name}>
      {({ field, form }) => (
        <Select
          {...field}
          {...props}
          // placeholder={placeholder}
          value={field.value || ''}
          onChange={(e) => {
            form.setFieldValue(name, e.target.value);
          }}
        >
          {/* <option value="" disabled>{placeholder}</option> */}
          {list.map((option) => (
            <option key={option[keyProp]} value={option[keyProp]}>
              {option[displayProp]}
            </option>
          ))}
        </Select>
      )}
    </Field>
  </FormControl>
);
