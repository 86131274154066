import React, { useEffect, useState } from 'react';

import { MdHelp, MdKeyboardArrowDown, MdKeyboardArrowUp, MdOpenInNew, MdOutlineListAlt } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Flex, IconButton, Image, Td, Text, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import iconEfetiva from '../../../../../assets/svgs/origem-efetiva.svg';
import iconEsperada from '../../../../../assets/svgs/origem-esperada.svg';
import requests from '../../../../../services/requests';
import { processCheck, processUncheck } from '../../../../../utils/exports';
import { capitalize, limitChars } from '../../../../../utils/strings';
import {
  convertColorTrip,
  descriptionStatusTrip,
  generateLabelSelect,
  modalityIcon,
  modalityTitle,
} from '../../../../../utils/translate';
import DocumentModalView from '../../../CurrentTrips/components/Documents/DocumentModalView';

import CteDetails from './CteDetails';

const TavelListCollapse = ({ entity, loadData }) => {
  let navigate = useNavigate();

  const { isOpen, onToggle } = useDisclosure();

  const [currentIdentifier, setCurrentIdentifier] = useState(null);
  const [isOpenModalViewFile, setIsOpenModalViewFile] = useState(false);
  const [currentCode, setCurrentCode] = useState(null);
  const [percentageCompletion, setPercentageCompletion] = useState(entity?.percentageCompletion ?? null);

  const getIcon = isOpen ? <MdKeyboardArrowUp color="#6C48C2" /> : <MdKeyboardArrowDown color="#6C48C2" />;

  const calcCarbon = entity?.carbonTrack?.calculated + entity?.carbonTrack?.retired;

  const calcCarbonFormatted = calcCarbon.toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 });

  const handleOpen = (identifier, code) => {
    setCurrentIdentifier(identifier);
    setCurrentCode(code);
    setIsOpenModalViewFile(!isOpenModalViewFile);
  };

  const percentage = (modality, status) => {
    let text;

    if (status === 'COMPLETED') {
      text = 'Viagem concluída';
    } else {
      switch (modality) {
        case 'FRETE_NAO_RASTREAVEL':
          text = 'Porcentagem estimada de conclusão com base nos horários previstos';
          break;
        case 'APP_MOTORISTA':
        case 'OPENTECH':
          text = 'Porcentagem estimada de conclusão com base na distância';
          break;
        default:
          text = 'Carregando...';
          break;
      }
    }

    return text;
  };

  const getColor = (modality) => {
    if (modality == 'FRETE_NAO_RASTREAVEL') {
      return '#f1c40f';
    } else if (modality == 'FRETE_RASTREAVEL') {
      return '#bababa';
    }

    return '#a885f9';
  };

  const tooltipStart = (
    <>
      {entity?.advanceTripStart != null && <Text>Adiantou: {entity?.advanceTripStart} </Text>}
      {entity?.delayTripStart != null && <Text>Atrasou: {entity?.delayTripStart} </Text>}
      {entity?.advanceTripStart == null && entity?.delayTripStart == null && <Text>No horário</Text>}
    </>
  );

  const tooltipEnd = (
    <>
      {entity?.advanceTripEnd != null && <Text>Adiantou: {entity?.advanceTripEnd} </Text>}
      {entity?.delayTripEnd != null && <Text>Atrasou: {entity?.delayTripEnd} </Text>}
      {entity?.advanceTripEnd == null && entity?.delayTripEnd == null && <Text>No horário</Text>}
    </>
  );

  const renderDocsNumber = (entity) => {
    const { docsNumber } = entity ?? {};
    const docsLength = docsNumber?.length ?? 0;

    if (docsLength === 0) {
      return <Text>-</Text>;
    } else {
      const firstDoc = docsNumber[0] ?? '-';
      const remainingDocs = docsNumber.slice(1).join(', ');

      return (
        <>
          <Text>{firstDoc}</Text>
          {docsLength > 1 && renderTooltip(docsLength - 1, remainingDocs)}
        </>
      );
    }
  };

  const renderTooltip = (count, remainingDocs) => (
    <Tooltip label={remainingDocs}>
      <Box>{`+${count}`}</Box>
    </Tooltip>
  );

  const fetchPercentageCompletion = async (identifier) => {
    try {
      const response = await requests.tripPercentage(identifier);
      setPercentageCompletion(response);
    } catch (error) {
      console.error('Erro ao buscar o percentual de conclusão:', error);
    }
  };

  useEffect(() => {
    if (!entity?.percentageCompletion) {
      fetchPercentageCompletion(entity?.identifier);
    } else {
      setPercentageCompletion(entity?.percentageCompletion);
    }
  }, [entity?.identifier]);

  return (
    <>
      <style>
        {`
        .css-1y6ic72 {
          margin-left: 0px;
        }
      `}
      </style>

      <Tr cursor="pointer" className="border" onClick={onToggle}>
        <Td>
          <Box h="70px !important">{getIcon}</Box>
        </Td>

        <Td title={modalityTitle(entity.modality, entity.typeExternalCode, entity.externalCode)}>
          <Box h="70px !important">
            <Box h="1.1rem">{modalityIcon(entity?.modality, entity?.typeExternalCode, entity?.externalCode)}</Box>
          </Box>
        </Td>

        <Td>
          <Flex
            direction="column"
            align="flex-start !important"
            justify={entity?.externalCode ? 'space-between' : 'center'}
            h="70px !important">
            <Text fontSize="18px" fontWeight="bold" textColor="linkColor" onClick={(event) => event.stopPropagation()}>
              #{entity?.code}
            </Text>

            {entity?.externalCode && (
              <Flex
                gap="5px"
                align="flex-start"
                width={
                  entity?.typeExternalCode == 'OPENTECH' || (entity.modality == 'APP_MOTORISTA' && entity?.externalCode?.length === 8)
                    ? '135px'
                    : 'auto'
                }>
                <Text fontSize="0.8rem">Cód.: {entity?.externalCode}</Text>

                <Tooltip label={generateLabelSelect(entity?.typeExternalCode)}>
                  <Box>
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              </Flex>
            )}
          </Flex>
        </Td>

        <Td textColor="#0F0A1DB3" minW="190px">
          <Box h="70px !important">
            {entity?.clientsFormatted?.length === 0
              ? 'Nenhuma empresa vinculada'
              : entity?.clientsFormatted?.length + ` vinculada${entity?.clientsFormatted?.length > 1 ? 's' : ''}`}

            {entity?.clientsFormatted?.length > 0 && (
              <Tooltip label={entity?.clientsFormatted?.join(', ')}>
                <Box pl="2px">
                  <MdHelp color="#422C76" />
                </Box>
              </Tooltip>
            )}
          </Box>
        </Td>

        <Td textColor="#0F0A1DB3">
          <Box h="70px !important" minW="200px">
            {entity?.userDriverName?.length > 25 ? (
              <Tooltip label={entity?.userDriverName}>{capitalize(entity?.userDriverName)}</Tooltip>
            ) : (
              capitalize(entity?.userDriverName)
            )}
          </Box>
        </Td>

        <Td textColor="#0F0A1DB3">
          <Flex h="70px !important" direction="column" justify="space-between" align="flex-start !important" minW="250px">
            <Flex title="Data esperada" alignItems="center">
              <Image src={iconEsperada} w="1rem" h="1rem" mr="1" />
              {entity?.startsAtFormatted ?? 'Data não disponível'}
            </Flex>

            <Flex alignItems="center">
              <Flex title="Data efetiva" alignItems="center">
                <Image src={iconEfetiva} w="1rem" h="1rem" mr="1" />
                {entity?.startsEtdFormatted ?? 'Data não disponível'}
              </Flex>

              {entity?.startsEtdFormatted != null && (
                <Tooltip label={tooltipStart}>
                  <Box style={{ paddingLeft: '2px' }}>
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </Td>

        <Td textColor="#0F0A1DB3">
          <Flex h="70px !important" direction="column" justify="space-between" align="flex-start !important" minW="200px">
            <Flex align="flex-start !important" justify="center">
              <Flex title="Data esperada">
                <Image src={iconEsperada} w="1rem" h="1rem" mr="1" />
                {entity?.endsAtFormatted ?? 'Data não disponível'}
              </Flex>

              {entity?.endsEtdFormatted != null && (
                <Tooltip label={tooltipEnd}>
                  <Box style={{ paddingLeft: '4px' }}>
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              )}
            </Flex>

            <Flex alignItems="center" mt="1">
              <Flex title="Data efetiva" alignItems="center">
                <Image src={iconEfetiva} w="1rem" h="1rem" mr="1" />
                {entity?.endsEtdFormatted ?? 'Data não disponível'}
              </Flex>
            </Flex>
          </Flex>
        </Td>

        <Td textColor="#0F0A1DB3">
          <Flex h="70px !important" align="center">
            <Tooltip label={percentage(entity.typeExternalCode, entity.status)}>
              <Text color="#8364CB">{percentageCompletion !== null ? `(${percentageCompletion}%)` : 'Carregando...'}</Text>
            </Tooltip>
          </Flex>
        </Td>

        <Td>
          <Flex gap="5px" h="70px !important" justify="space-between">
            <Flex direction="column" w="max-content">
              {renderDocsNumber(entity)}
            </Flex>

            {entity.qttFiles > 0 && (
              <Flex>
                <Tooltip label="Ver arquivos">
                  <Box>
                    <IconButton
                      bg="transparent"
                      icon={<MdOutlineListAlt size={20} color="#6C48C2" />}
                      onClick={(decision) => {
                        if (decision) {
                          decision.stopPropagation();
                          handleOpen(entity?.identifier, entity?.code);
                        }
                      }}
                    />
                  </Box>
                </Tooltip>
              </Flex>
            )}
          </Flex>
        </Td>

        <Td>
          <Flex h="70px !important" align="center" minW="150px">
            <Tooltip label={descriptionStatusTrip(entity.status)}>
              <Flex
                py="5px"
                px="20px"
                justify="center"
                borderRadius="15px"
                bgColor={convertColorTrip(entity.status)}
                color="white"
                maxH="30px">
                {limitChars(descriptionStatusTrip(entity.status), 13)}
              </Flex>
            </Tooltip>
          </Flex>
        </Td>

        <Td>
          <Flex align="center" justify="flex-start" h="70px !important" gap="5px">
            <Tooltip label={entity?.carbonTrack?.retired > 0 ? 'Compensado' : 'Não compensado'}>
              <Flex
                w="max-content"
                maxH="30px"
                p="7px 11px"
                border="1px"
                borderColor="#2ECC71"
                color="#2ECC71"
                direction="row"
                align="center"
                borderRadius="10px"
                gap="5px">
                Pegada CO₂: {calcCarbonFormatted}t
                {entity?.carbonTrack?.retired > 0 ? (
                  <Image src={processCheck} opacity={'1'} />
                ) : (
                  <Image src={processUncheck} opacity={'1'} />
                )}
              </Flex>
            </Tooltip>
          </Flex>
        </Td>

        <Td>
          <Flex align="center" justify="flex-end" h="70px !important" gap="5px" w="auto" onClick={(event) => event.stopPropagation()}>
            <Tooltip label="Detalhe da viagem" placement="left">
              <Link to={`/logistic/trip/detail/${entity.identifier}`} target="_blank" cursor="pointer">
                <IconButton bg="transparent" icon={<MdOpenInNew size={20} color="#6C48C2" />} />
              </Link>
            </Tooltip>
          </Flex>
        </Td>
      </Tr>

      <Tr>
        <Td p="0" colSpan="12" border="none">
          <Box overflowX="auto">
            <CteDetails isOpenCollapse={isOpen} trip={entity} loadData={loadData} />
          </Box>
        </Td>
      </Tr>

      <DocumentModalView isOpen={isOpenModalViewFile} identifier={currentIdentifier} code={currentCode} onClose={handleOpen} />
    </>
  );
};

export default TavelListCollapse;
