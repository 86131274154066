import React from 'react';

import { Grid } from '@chakra-ui/react';

import { renderField } from '../../../../components/CommonForm/Fields/renderField';

import { renderSelectField } from './renderSelectField';

const DynamicForm = ({ values }) => {
  // Opções para o campo 'ownership'
  const ownershipOptions = [
    { value: 'VENDEMMIA', name: 'Vendemmia' },
    { value: 'PROPRIO', name: 'Próprio' },
    { value: 'TERCEIRO', name: 'Terceiro' },
  ];

  // Opções para o campo 'typeCar'
  const typeCarOptions = [
    { value: 'Caminhão', name: 'Caminhão' },
    { value: 'Carreta', name: 'Carreta' },
    { value: 'Caminhonete' , name: 'Caminhonete' },
  ];

  // Mapeamento dos labels
  const labels = {
    licensePlate: 'Placa',
    brand: 'Marca',
    color: 'Cor',
    typeCar: 'Tipo de Veículo',
    modelVehicle: 'Tipo de carreta',
    ownership: 'Propriedade',
    capacity: 'Capacidade',
    yearManufacture: 'Ano de Fabricação',
    model: 'Modelo',
    yearModel: 'Ano do Modelo',
  };

  // Função para formatar o rótulo (label) de cada campo
  const formatLabel = (key) => labels[key] || key;

  // Mapeamento dos tipos de campo
  const fieldTypes = {
    ownership: 'select',
    typeCar: 'select',
  };

  // Função para determinar o tipo do campo (input ou select)
  const getFieldType = (key) => fieldTypes[key] || 'input';

  // Função para renderizar o campo adequado, dependendo do tipo
  const renderDynamicField = (key, item = '') => {
    const type = getFieldType(key);
    const label = formatLabel(key);

    if (type === 'select') {
      // Se for um campo do tipo 'select', chama renderSelectField
      const list = key === 'ownership' ? ownershipOptions : typeCarOptions;

      return (
        <React.Fragment key={key}>
          {renderSelectField({
            name: key,
            placeholder: label,
            list: list,
            keyProp: 'value', // Substitua pela chave correta da lista
            displayProp: 'name', // Substitua pelo valor a ser exibido
            value: item, // Preenche o valor (padrão será '')
          })}
        </React.Fragment>
      );
    }

    // Se for um campo do tipo 'input', chama renderField
    return (
      <React.Fragment key={key}>
        {renderField(key, label, item)} {/* Passa o valor para o campo */}
      </React.Fragment>
    );
  };

  // Se `values` for nulo ou undefined, inicializa com objeto vazio
  const safeValues = values || {};
  // Cria campos para todas as chaves do labels, usando `values` quando disponível
  return (
    <Grid gridTemplateColumns="1fr 1fr" gap="25px">
      {Object.keys(labels).map((key) => {
        return renderDynamicField(key, safeValues[key]);
      })}
    </Grid>
  );
};

export default DynamicForm;

