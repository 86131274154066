import React, { useState } from 'react';

import { MdOutlineEdit } from 'react-icons/md';

import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';

import { CommonDrawer } from '../../../../components/CommonDrawer/CommonDrawer';
import requests from '../../../../services/requests';
import { executeRequest } from '../../../../utils/requests/executeRequest';
import DynamicForm from '../../VehicleManagement/components/DynamicForm';
// import DynamicForm from '../DynamicForm';

const yup = require('yup');

const ModalVehicleEdition = ({ entity, setIsLoading, triggerRefresh }) => {
  const [initialValues, setInitialValues] = useState(null);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpenEditingModal = async () => {
    const res = await executeRequest({
      action: () => requests.getDetailsVehicle(entity.identifier),
    });

    updateInitialValues(res);
    onOpen();
  };

  const updateInitialValues = (entity) => {
    const updatedValues = {
      licensePlate: entity.licensePlate,
      brand: entity.brand,
      color: entity.color,
      typeCar: entity.typeCar ?? 'Caminhão',
      modelVehicle: entity.modelVehicle,
      ownership: entity.ownership ?? 'VENDEMMIA',
      capacity: entity.capacity,
      yearManufacture: entity.yearManufacture,
      model: entity.model,
      yearModel: entity.yearModel,
    };

    setInitialValues(updatedValues);
  };

  const validationSchema = yup.object().shape({
    licensePlate: yup.string().required('Campo obrigatório'),
  });

  const handleEditVehicle = async (values) => {
    await executeRequest({
      action: () => requests.editVehicle(entity?.identifier, values),
      msgSuccess: 'Veículo editado com sucesso',
      msgError: 'Erro ao editar o veículo, tente novamente.',
      setIsLoadingEdit,
      triggerRefresh,
      callback: () => onClose()
    });
  };

  return (
    <>
      <Tooltip label="Editar veículo">
        <IconButton bgColor="#D0B0FD36" icon={<MdOutlineEdit size={20} color="#6C48C2" />} onClick={() => handleOpenEditingModal()} />
      </Tooltip>

      <CommonDrawer
        heading="Dados do veículo"
        placement="left"
        hasForm={true}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        formik={{ initialValues, validationSchema }}
        callback={handleEditVehicle}
        isLoading={isLoadingEdit}>
        <DynamicForm values={initialValues} />
      </CommonDrawer>
    </>
  );
};

export default ModalVehicleEdition;
