import { Button, Tooltip } from '@chakra-ui/react';

// Função para determinar se o botão deve ser renderizado
const shouldRender = (buttonProps, condition = true) => {
  return typeof condition === 'function' ? condition(buttonProps) : condition;
};

// Função para verificar se o usuário tem permissão para ver o botão
const hasButtonPermission = (allowedRoles = [], deniedRoles = []) => {
  const userRole = localStorage.getItem('profile-role')?.trim();

  if (!userRole) return false;

  // Se o usuário estiver nas deniedRoles, negar acesso
  if (deniedRoles.includes(userRole)) {
    return false;
  }

  // Se houver allowedRoles, permitir acesso apenas se o usuário estiver incluído
  if (allowedRoles.length > 0) {
    return allowedRoles.includes(userRole);
  }

  // Se não houver restrições, permitir acesso
  return true;
};

const CustomButtons = ({ buttons, buttonProps, identifier, subCategoriesProps }) => {
  return buttons?.map((button, index) => {
    const isVisible = shouldRender(buttonProps, button.isButtonVisible);
    const hasPermission = hasButtonPermission(button.allowedRoles, button.deniedRoles);

    if (!isVisible || !hasPermission) {
      return null;
    }

    // Verifica se o botão está desabilitado
    const { isButtonDisabled = false } = button;
    const isDisabled = shouldRender(buttonProps, isButtonDisabled);

    return (
      <Tooltip key={index} label={button.tooltip || ''}>
        <Button
          p={button.icon ? '0' : '0 15px'}
          variant="commonTable"
          onClick={(e) => {
            e.stopPropagation();
            // TODO: tratar para já receber o cambo via prop
            if (button.label === 'Detalhes OR') {
              button.action(buttonProps, subCategoriesProps);
            } else {
              button.action(identifier, subCategoriesProps);
            }
          }}
          isDisabled={isDisabled}>
          {button.icon || button.label}
        </Button>
      </Tooltip>
    );
  });
};

export default CustomButtons;
