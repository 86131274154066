import { Field } from 'formik';

import { FormControl, FormLabel, Input } from '@chakra-ui/react';

export const renderField = (name, placeholder, props) => (
  <FormControl>
    <FormLabel>{placeholder}</FormLabel>

    <Field name={name}>{({ field }) => <Input {...field} placeholder={placeholder} {...props} />}</Field>
  </FormControl>
);
