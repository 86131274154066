import { Formik } from 'formik'; // Importa o Formik, uma biblioteca de gerenciamento de formulários em React.

import { Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Text, useDisclosure } from '@chakra-ui/react'; // Importa componentes do Chakra UI para criar a interface do Drawer (gaveta).

import { CustomSubmitButton } from '../../components/CommonForm/components/CustomSubmitButton'; // Importa um botão personalizado para ser utilizado no rodapé do formulário.

import { DrawerFormikStyles } from './styles/DrawerFormikStyles'; // Importa estilos personalizados para o Drawer quando utilizado com o Formik.

export const CommonDrawer = ({
  placement, // Define a posição da gaveta (top, right, bottom, left).
  heading, // Título que aparece no cabeçalho do Drawer.
  children, // Conteúdo que será renderizado dentro do Drawer (pode ser um formulário ou outros componentes).
  callback, // Função de callback chamada quando o formulário for submetido.
  footer, // Conteúdo personalizado para o rodapé do Drawer (quando não há formulário).
  buttonName, // Nome que será exibido no botão de submissão do formulário.
  initialValues, // Valores iniciais para o formulário do Formik.
  isOpen: propIsOpen, // Define se o Drawer está aberto (controlado por uma prop externa).
  onOpen: propOnOpen, // Função chamada quando o Drawer é aberto (controlado externamente).
  onClose: propOnClose, // Função chamada para fechar o Drawer (controlado externamente).
  hasForm = false, // Indica se o Drawer contém um formulário ou não.
  loadingText, // Texto exibido quando o botão de submissão está carregando.
  isLoading, // Indica se o botão de submissão do formulário está em estado de carregamento.
  formik, // Props adicionais para o Formik.
  size = 'xl', // tamanho opcional do drawer a ser aberto
  showFooter = true, // tamanho opcional do drawer a ser aberto
  ...props // Outras props que podem ser passadas para o componente Drawer.
}) => {
  const { isOpen: drawerIsOpen, onOpen: drawerOnOpen, onClose: drawerOnClose } = useDisclosure();
  // useDisclosure é um hook do Chakra UI para gerenciar o estado de abertura e fechamento do Drawer internamente.

  const handleClose = () => {
    if (propOnClose) {
      propOnClose(); // Se uma função onClose for passada via props, ela será chamada ao fechar o Drawer.
    } else {
      drawerOnClose(); // Caso contrário, usa o controle interno do estado de fechamento.
    }
  };

  return (
    <Drawer placement={placement} isOpen={propIsOpen || drawerIsOpen} onClose={handleClose} size={size} {...props}>
      {/* Drawer é o componente principal que representa uma gaveta que aparece na tela. */}

      <DrawerOverlay />
      {/* DrawerOverlay adiciona um fundo escurecido atrás da gaveta. */}

      <DrawerContent>
        {/* DrawerContent define o conteúdo principal dentro do Drawer. */}
        <DrawerHeader p="25px" borderBottomWidth="1px" borderColor={'#dfdfdf'} paddingBottom={'20px'}>
          {heading && (
            <Text
              textStyle="secondaryTitle"
              fontSize={{ sm: '23px', md: '28px' }}
              borderLeft={'6px solid #A885F9'}
              paddingLeft={'18px'}
              textColor="#0F0A1D"
              className="up-anim"
              color="primary">
              {heading} {/* Exibe o título do Drawer, se fornecido. */}
            </Text>
          )}
        </DrawerHeader>

        {hasForm ? (
          <Formik
            initialValues={initialValues} // Define os valores iniciais do formulário.
            onSubmit={(values) => {
              callback && callback(values); // Quando o formulário for submetido, chama a função callback com os valores do formulário.
            }}
            {...formik} // Passa props adicionais para o Formik, se houver.
          >
            {({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                style={{
                  height: '100%',
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}>
                {/* Renderiza o formulário, usando o Formik. */}

                <DrawerFormikStyles />
                {/* DrawerFormikStyles aplica estilos personalizados ao formulário dentro do Drawer. */}

                <DrawerBody p="25px">{children}</DrawerBody>
                {/* DrawerBody renderiza o conteúdo principal do Drawer (geralmente campos de formulário ou outros componentes). */}

                <DrawerFooter p="25px 0px 0px 0px" borderTopWidth="1px" borderColor={'#dfdfdf'}>
                  {/* DrawerFooter contém o rodapé do Drawer, aqui usamos o botão de submissão do formulário. */}

                  <CustomSubmitButton name={buttonName} loadingText={loadingText} isLoading={isLoading} />
                  {/* CustomSubmitButton é um botão de submissão personalizado. */}
                </DrawerFooter>
              </form>
            )}
          </Formik>
        ) : (
          <>
            {/* Caso o Drawer não tenha um formulário, apenas renderiza o conteúdo normal e o rodapé. */}
            <DrawerBody p="25px">{children}</DrawerBody>

            {showFooter && (
              <DrawerFooter p="25px 0px 0px 0px" borderTopWidth="1px" borderColor={'#dfdfdf'}>
                {footer}
              </DrawerFooter>
            )}
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
};
