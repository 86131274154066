import React from 'react';

import { Box, Flex, Table, Tbody, Text, Th, Thead, Tr } from '@chakra-ui/react';

import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';

import TavelListCollapse from './TavelListCollapse';

import './styles.css';

const headers = ['', '', 'Número', 'Empresas vinculadas', 'Motorista', 'Início', 'Conclusão', '', 'Arquivos', 'Status', '', ''];

const TableTravel = ({ list, load, loadData = () => {} }) => {
  return (
    <ScreenLoader isLoading={load} overflowY="auto" w="auto" m="10px">
      <Table w="full">
        <Thead>
          <Tr className="border">
            {headers.map((header, index) => (
              <Th key={index}>
                <Box p="10px !important">{header && <Text>{header}</Text>}</Box>
              </Th>
            ))}
          </Tr>
        </Thead>

        <Tbody>
          {list?.map((entity, key) => {
            return <TavelListCollapse entity={entity} key={key} loadData={loadData} />;
          })}
        </Tbody>
      </Table>

      {!load && list?.length === 0 && (
        <Flex justify="center" my="25px">
          Nenhum dado encontrado para os parâmetros filtrados.
        </Flex>
      )}
    </ScreenLoader>
  );
};

export default TableTravel;
