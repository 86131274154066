import React, { useEffect, useState } from 'react';

import { FaTruck, FaUser } from 'react-icons/fa';
import { MdHelp, MdKeyboardArrowDown, MdKeyboardArrowUp, MdLocationPin, MdOpenInNew, MdOutlineListAlt } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { Box, Collapse, Flex, IconButton, Image, Td, Text, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import logo from '../../../../assets/images/gatsby-icon.png';
import iconEfetiva from '../../../../assets/svgs/origem-efetiva.svg';
import iconEsperada from '../../../../assets/svgs/origem-esperada.svg';
import useResponsiveCheck from '../../../../hooks/useResponsiveCheck';
import requests from '../../../../services/requests';
import { processCheck, processUncheck } from '../../../../utils/exports';
import { limitChars } from '../../../../utils/strings';
import { convertColorTrip, descriptionStatusTrip, modalityIcon, modalityTitle } from '../../../../utils/translate';

import DocumentModalView from './Documents/DocumentModalView';

export default function ListCollapse({ entity, entityMarker = null, setCurrentTripData = null, handleCurrentTrip }) {
  let navigate = useNavigate();
  const [currentIdentifier, setCurrentIdentifier] = useState(null);
  const [isOpenModalViewFile, setIsOpenModalViewFile] = useState(false);
  const [currentCode, setCurrentCode] = useState(null);
  const { isOpen, onToggle } = useDisclosure();
  const [percentageCompletion, setPercentageCompletion] = useState(entity?.percentageCompletion ?? null);

  const handleOpen = (identifier, code) => {
    setCurrentIdentifier(identifier);
    setCurrentCode(code);
    setIsOpenModalViewFile(!isOpenModalViewFile);
  };

  const mobile = useResponsiveCheck();
  const viewport = mobile.viewport;

  const tooltipStart = (
    <>
      {entity?.advanceTripStart != null && <Text>Adiantou: {entity?.advanceTripStart} </Text>}
      {entity?.delayTripStart != null && <Text>Atrasou: {entity?.delayTripStart} </Text>}
      {entity?.advanceTripStart == null && entity?.delayTripStart == null && <Text>No horário</Text>}
    </>
  );

  const tooltipEnd = (
    <>
      {entity?.advanceTripEnd != null && <Text>Adiantou: {entity?.advanceTripEnd} </Text>}
      {entity?.delayTripEnd != null && <Text>Atrasou: {entity?.delayTripEnd} </Text>}
      {entity?.advanceTripEnd == null && entity?.delayTripEnd == null && <Text>No horário</Text>}
    </>
  );

  const Icon = isOpen ? <MdKeyboardArrowUp color="#707070" size="20" /> : <MdKeyboardArrowDown color="#707070" size="20" />;

  const statusTrip = descriptionStatusTrip(entity?.status);
  const showHelpStartTrip = entity?.startsEtdFormatted != null && entity?.startsEtdFormatted != '' ? true : false;

  const checkPlate = () => {
    if (entity?.plates) {
      return entity?.plates;
    } else if (entity?.vehicle?.licensePlate) {
      return entity?.vehicle?.licensePlate;
    } else {
      return '-';
    }
  };

  const fetchPercentageCompletion = async (identifier) => {
    try {
      const response = await requests.tripPercentage(identifier);
      setPercentageCompletion(response);
    } catch (error) {
      console.error('Erro ao buscar o percentual de conclusão:', error);
    }
  };

  useEffect(() => {
    if (!entity?.percentageCompletion) {
      fetchPercentageCompletion(entity?.identifier);
    } else {
      setPercentageCompletion(entity?.percentageCompletion);
    }
  }, [entity?.identifier]);

  return (
    <>
      <Tr id={entity.identifier} className="border">
        {/* collapse */}
        <Td cursor="pointer" onClick={onToggle} sx={{ m: 0, p: 0 }}>
          <Box align="center" sx={{ m: 0, p: 0 }} h="64px !important">
            {Icon}
          </Box>
        </Td>

        {/* modality */}
        <Td border="none" sx={{ m: 0, p: 0 }}>
          <Tooltip
            label={modalityTitle(entity?.modality, entity?.typeExternalCode, entity?.externalCode)}
            aria-label={modalityTitle(entity?.modality, entity?.typeExternalCode, entity?.externalCode)}>
            <Box align="center" sx={{ m: 0, p: 0 }} h="64px !important">
              {modalityIcon(entity?.modality, entity?.typeExternalCode, entity?.externalCode)}
            </Box>
          </Tooltip>
        </Td>

        {/* status */}
        <Td>
          <Flex h="64px !important">
            <Text
              p="5px 12px"
              bg={convertColorTrip(entity.status)}
              color="white"
              borderRadius="6px"
              fontSize="10px"
              fontWeight="bold"
              w="fit-content">
              {statusTrip}
            </Text>
          </Flex>
        </Td>

        {/* viagem */}
        <Td cursor="pointer" sx={{ m: 0, p: 0 }}>
          <Flex direction="column" align="flex-start !important" h="64px !important" justify="space-between">
            <Text
              textColor="linkColor"
              onClick={(decision) => {
                if (decision) {
                  navigate(`/logistic/trip/detail/${entity.identifier}`, {
                    state: {
                      historyBack: '/logistic/trip/timeline',
                    },
                  });
                }
              }}>
              #{entity?.code}
            </Text>

            {entity?.externalCode && (
              <Flex
                gap="3px"
                width={
                  entity?.typeExternalCode == 'OPENTECH' || (entity.modality == 'APP_MOTORISTA' && entity?.externalCode?.length == 8)
                    ? '135px'
                    : 'auto'
                }>
                <Text fontSize="0.8rem">Cód.: {entity?.externalCode}</Text>

                <Box>
                  <Tooltip label="Código externo (integração para rastreio)">
                    <Box style={{ paddingLeft: '1px' }}>
                      <MdHelp color="#422C76" />
                    </Box>
                  </Tooltip>
                </Box>
              </Flex>
            )}
          </Flex>
        </Td>

        {/* docs */}
        <Td bgColor={'transparent'} sx={{ m: 0, p: 0 }}>
          <Flex h="64px !important" align="center" justify="space-between">
            <Text>{entity?.docsNumber && entity?.docsNumber.length > 0 ? entity.docsNumber.join(', ') : '-'}</Text>

            <Box>
              {entity.qttFiles > 0 && (
                <Flex>
                  <Tooltip label="Ver arquivos">
                    <Box>
                      <IconButton
                        bg="transparent"
                        icon={<MdOutlineListAlt size={20} color="#6C48C2" />}
                        onClick={(decision) => {
                          if (decision) {
                            handleOpen(entity?.identifier, entity?.code);
                          }
                        }}
                      />
                    </Box>
                  </Tooltip>
                </Flex>
              )}
            </Box>
          </Flex>
        </Td>

        {/* empresa */}
        <Td sx={{ m: 0, p: 0 }}>
          <Flex h="64px !important">
            <Tooltip label={entity?.clientsFormatted?.join(',')}>
              <Text>{limitChars(entity?.clientsFormatted?.join(','), 30)}</Text>
            </Tooltip>
          </Flex>
        </Td>

        {/* trasporte */}
        <Td sx={{ m: 0, p: 0 }}>
          <Flex flexDirection="column" h="64px !important" align="flex-start !important" justify="space-between">
            <Flex alignItems="center">
              <FaUser color="#8364CB" />
              {entity?.userDriverName?.length > 30 ? (
                <Tooltip label={entity?.userDriverName ?? 'Não identificado'} ml="4">
                  <Box ml="1">{entity?.userDriverName ? limitChars(entity?.userDriverName, 30) : 'Não identificado'}</Box>
                </Tooltip>
              ) : (
                <Box ml="1">{entity?.userDriverName ?? 'Não identificado'} </Box>
              )}
            </Flex>

            <Flex alignItems="center">
              <Tooltip label={entity?.shippingCompanyName ?? 'Não identificado'}>
                <Box mr="1">
                  {entity?.shippingCompanyName?.toLowerCase() == 'vendemmia' ? (
                    <Image src={logo} alt="Logo" margin="auto" width="19px" />
                  ) : (
                    <FaTruck color="#A0A0A0" />
                  )}
                </Box>
              </Tooltip>
              {checkPlate()}
            </Flex>
          </Flex>
        </Td>

        {/* inicio */}
        <Td textStyle="subtitle" textColor="#0F0A1DB3" sx={{ m: 0, p: 0 }}>
          <Flex h="64px !important" alignItems="flex-start !important" direction="column" justify="space-between">
            <Flex title="Data esperada" alignItems="center">
              <Image src={iconEsperada} w="1rem" h="1rem" mr="1" />
              {entity?.startsAtFormatted ?? 'Data não disponível'}
            </Flex>

            <Flex alignItems="center" mt="1">
              <Flex title="Data efetiva" alignItems="center">
                <Image src={iconEfetiva} w="1rem" h="1rem" mr="1" />
                {entity?.startsEtdFormatted ?? 'Data não disponível'}
              </Flex>

              {entity?.startsEtdFormatted != null && (
                <Tooltip label={tooltipStart}>
                  <Box style={{ paddingLeft: '2px' }}>
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </Td>

        {/* conclusão */}
        <Td textStyle="subtitle" textColor="#0F0A1DB3" sx={{ m: 0, p: 0 }}>
          <Flex alignItems="flex-start !important" direction="column" h="64px !important">
            <Flex alignItems="center">
              <Flex title="Data esperada">
                <Image src={iconEsperada} w="1rem" h="1rem" mr="1" />
                {entity?.endsAtFormatted ?? 'Data não disponível'}
              </Flex>
              <Text ml="2" title="Conclusão da viagem (aproximada)" color="#8364CB">
                {percentageCompletion !== null ? `(${percentageCompletion}%)` : 'Carregando...'}
              </Text>

              {entity?.endsEtdFormatted != null && (
                <Tooltip label={tooltipEnd}>
                  <Box style={{ paddingLeft: '4px' }}>
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              )}
            </Flex>

            <Flex alignItems="center" mt="1">
              <Flex title="Data efetiva" alignItems="center">
                <Image src={iconEfetiva} w="1rem" h="1rem" mr="1" />
                {entity?.endsEtdFormatted ?? 'Data não disponível'}
              </Flex>
            </Flex>
          </Flex>
        </Td>

        {/* detalhes */}
        <Td sx={{ m: 0, p: 0 }}>
          <Flex direction="row" gap="5px" h="64px !important" justify="space-between">
            <Tooltip label={entity?.carbonTrack?.retired > 0 ? 'Compensado' : 'Não compensado'}>
              <Flex w="fit-content" p="7px 11px" border="1px" borderColor="#2ECC71" color="#2ECC71" borderRadius="10px" gap="5px">
                Pegada CO₂:{' '}
                {entity?.carbonTrack?.calculated.toLocaleString('pt-BR', {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                })}
                t
                {entity?.carbonTrack?.retired > 0 ? (
                  <Image src={processCheck} opacity={'1'} />
                ) : (
                  <Image src={processUncheck} opacity={'1'} />
                )}
              </Flex>
            </Tooltip>

            <Flex gap="5px">
              <Tooltip label="Detalhe da viagem" placement="left">
                <Box sx={{ m: 0, p: 0 }}>
                  <IconButton
                    bg="transparent"
                    icon={<MdOpenInNew size={20} color="#6C48C2" />}
                    onClick={(decision) => {
                      if (decision) {
                        navigate(`/logistic/trip/detail/${entity.identifier}`, {
                          state: {
                            historyBack: '/logistic/trip/timeline',
                          },
                        });
                      }
                    }}
                  />
                </Box>
              </Tooltip>

              <Tooltip label="Visualizar trajeto da viagem" placement="left">
                <Box sx={{ m: 0, p: 0 }}>
                  <IconButton
                    bg="transparent"
                    icon={<MdLocationPin size={20} color={entityMarker && entityMarker.color ? entityMarker.color : '#ea4335'} />}
                    onClick={() => {
                      if (typeof setCurrentTripData === 'function') {
                        handleCurrentTrip(entityMarker);
                      }
                    }}
                  />
                </Box>
              </Tooltip>
            </Flex>
          </Flex>
        </Td>
      </Tr>

      {isOpen && (
        <Tr w="full">
          <Td
            p="0"
            colSpan={15}
            border="1px"
            borderColor="#70707036"
            bgColor="#F9F9F9"
            style={{
              padding: 0,
            }}>
            <Collapse in={isOpen} animateOpacity>
              <Box m="1.2rem">
                {entity?.trajectories?.length > 0 &&
                  entity.trajectories.map(function (trajectory, key) {
                    let description = 'Coleta';

                    if (key == 0) {
                      description = 'Origem';
                    } else if (key == entity?.trajectories?.length - 1) {
                      description = 'Destino';
                    } else if (trajectory?.type == 'ENTREGA') {
                      description = 'Entrega';
                    }
                    return (
                      <Flex column="direction" key={key} gap="20px" mb="10px">
                        <Flex>
                          <Text textColor="primary" fontWeight="bold">
                            {description}
                          </Text>
                        </Flex>
                        <Flex>
                          <Text>{trajectory.address}</Text>
                        </Flex>
                      </Flex>
                    );
                  })}
              </Box>
            </Collapse>
          </Td>
        </Tr>
      )}

      <DocumentModalView isOpen={isOpenModalViewFile} identifier={currentIdentifier} code={currentCode} onClose={handleOpen} />
    </>
  );
}
