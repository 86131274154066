import React from 'react';

import { FieldArray, useFormikContext } from 'formik';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';

import { Box, Button, Divider, Grid, Tooltip } from '@chakra-ui/react';

import { renderAddressAutocomplete } from '../../../../components/CommonForm/Fields/renderAddressAutocomplete';
import { renderContactFields } from '../Helpers/renderContactFields';
import { renderField } from '../Helpers/renderField';

export const DynamicForm = () => {
  const { values, setFieldValue } = useFormikContext();

  // Garante que 'contacts' seja um array
  const contacts = values.contacts || [];
  const hasContacts = contacts.length > 0;

  // Função para adicionar um novo contato
  const addNewContact = () => {
    const newContact = { name: '', email: '' };
    setFieldValue('contacts', [...contacts, newContact]);
  };

  // Função para remover um contato pelo índice
  const removeContact = (index) => {
    setFieldValue(
      'contacts',
      contacts.filter((_, i) => i !== index)
    );
  };

  return (
    <Grid borderWidth="1px" borderRadius="md" borderColor={'#70707036'} p={4} gap="20px">
      <Grid gridTemplateColumns="1fr 1fr" gap="20px">
        {renderField('documentNumber', 'CNPJ do fornecedor', { maxLength: 14 })}
        {renderField('name', 'Nome do fornecedor')}
      </Grid>

      {renderAddressAutocomplete('address', 'Digite o endereço', setFieldValue)}

      {hasContacts && <Divider m={'20px 0'} />}

      <FieldArray
        name="contacts"
        render={() => (
          <>
            {contacts.map((contact, index) => (
              <Grid gridTemplateColumns={'1fr 80px'} key={index} alignItems="end">
                {/* Renderiza campos de contato */}
                {renderContactFields(index)}

                {/* Botão de Remover Contato */}
                <Button variant="remove" type="button" onClick={() => removeContact(index)} ml={2} size="sm" colorScheme="red">
                  <Tooltip label="Remover contato" fontSize="md">
                    <Box>
                      <FaTrashAlt color="#ec7063" size={16} />
                    </Box>
                  </Tooltip>
                </Button>
              </Grid>
            ))}
            <Button variant="add" type="button" maxW="216px" onClick={addNewContact}>
              <MdAdd /> Adicionar contato
            </Button>
          </>
        )}
      />
    </Grid>
  );
};
