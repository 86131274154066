import React, { useState } from 'react';

import { FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Box, Flex, Icon, IconButton, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import DefaultModal from '../../../../components/Modal/Template/DefaultModal';
import Paginate from '../../../../components/Paginate/Paginate';
import requests from '../../../../services/requests';
import ModalChangeToggle from '../Modals/ModalChangeToggle';
import ModalVehicleEdition from '../Modals/ModalVehicleEdition';

const VehiclesTable = ({ list, triggerRefresh, action, setAction, metadata, setMetadata, hideButtons = false, setIsLoading }) => {
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState(false);

  const handleDelete = (identifier) => {
    setIsLoadingDelete(true);

    requests
      .deleteVehicle(identifier)
      .then((response) => {
        setIsLoadingDelete(false);
        toast.success(`Veículo ${response?.deleted ? 'excluído' : 'inativado'} com sucesso!`);
        triggerRefresh();
      })
      .catch(() => {
        setIsLoadingDelete(false);
        toast.error('Problemas ao remover veículo, tente novamente ou entre em contato com o Administrador');
      });
  };

  const handleClick = (identifier, statusText) => {
    setIsLoadingChangeStatus(true);
    let status = 'activate';

    if (statusText === 'ATIVO') {
      status = 'inactivate';
    }

    requests
      .changeStatusVehicle(identifier, status)
      .then((response) => {
        toast.success(`Veículo ${status == 'activate' ? 'ativado' : 'inativado'} com sucesso!`);
        triggerRefresh();
        setIsLoadingChangeStatus(false);
      })
      .catch(() => {
        setIsLoadingChangeStatus(false);
        toast.error('Problemas ao alterar status do veículo, tente novamente ou entre em contato com o Administrador');
      });
  };

  return (
    <>
      <Table className="table-default">
        <Thead border="1px" borderBottom="1px">
          <Tr>
            <Th>Placa</Th>
            <Th>Marca</Th>
            <Th>Cor</Th>
            <Th>Tipo de veículo</Th>
            <Th>Tipo de carreta</Th>
            <Th>Propriedade</Th>
            <Th>Capacidade</Th>
            <Th>Ano de fabricação</Th>
            <Th>Modelo</Th>
            <Th>Ano do modelo</Th>
            <Th>Veículo próprio</Th>

            {!hideButtons && (
              <>
                <Th>Status</Th>
                <Th>Ações</Th>
              </>
            )}
          </Tr>
        </Thead>
        <Tbody border="1px">
          {list?.map((entity, key) => (
            <Tr key={key} style={{ '--delay': `0.${key}s` }}>
              <Td>{entity.licensePlate ?? '-'}</Td>
              <Td>{entity.brand ?? '-'}</Td>
              <Td>{entity.color ?? '-'}</Td>
              <Td>{entity.modelVehicle ?? '-'}</Td>
              <Td>{entity.typeCar ?? '-'}</Td>
              <Td>{entity.ownership ?? '-'}</Td>
              <Td>{entity.capacity ?? '-'}</Td>
              <Td>{entity.yearManufacture ?? '-'}</Td>
              <Td>{entity.model ?? '-'}</Td>
              <Td>{entity.yearModel ?? '-'}</Td>
              <Td>{entity.ownership === 'PROPRIO' ? 'SIM' : 'NÃO' ?? '-'}</Td>

              {!hideButtons && (
                <>
                  <Td>
                    <ModalChangeToggle
                      text=" esse veículo"
                      status={entity.status === 'ATIVO'}
                      callback={(decision) => {
                        if (decision) {
                          handleClick(entity.identifier, entity.status);
                        }
                      }}
                    />
                  </Td>

                  <Td>
                    <Flex align="center" gap="10px">
                      <ModalVehicleEdition entity={entity} setIsLoading={setIsLoading} triggerRefresh={triggerRefresh} />
                      <DefaultModal
                        enabled={entity?.countTrips > 0 ? false : true}
                        ctaButton={
                          <IconButton bgColor="#D0B0FD36" icon={<FaTrashAlt size={20} color="#E74C3C" />} />
                        }
                        title={'Excluir o veículo'}
                        loading={isLoadingDelete}
                        subtitle={
                          <>
                            Você tem certeza que deseja excluir esse veículo?
                            <br /> Esse veículo ficará indisponível para viagens.
                          </>
                        }
                        buttonColor="red"
                        txtButton="Excluir veículo"
                        callback={(decision) => {
                          if (decision) {
                            handleDelete(entity.identifier);
                          }
                        }}
                      />
                    </Flex>
                  </Td>
                </>
              )}
            </Tr>
          ))}

          {list?.length == 0 && (
            <Tr>
              <Td colSpan={13}>
                <Flex justify="center" my="25px">
                  Nenhum dado encontrado para os parâmetros filtrados.
                </Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {metadata.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </>
  );
};

export default VehiclesTable;
