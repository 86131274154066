import { useEffect, useState } from 'react';

import { MdAdd, MdOpenInNew } from 'react-icons/md';

import { Button, Text, useDisclosure } from '@chakra-ui/react';

import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import { CommonModal } from '../../../components/CommonModal/CommonModal';
import Page from '../../../components/Page';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import closeFloatActionButton from '../../../utils/actions/modal/closeFloatActionButton';
import { handleDocuments } from '../../../utils/documents/handleDocuments';
import { executeRequest } from '../../../utils/requests/executeRequest';
import addParametersToURL from '../../../utils/url/addParametersToURL ';
import hasSpecificURLParameters from '../../../utils/url/hasSpecificURLParameter';

import { commonListConfig } from './components/commonListConfig';
import { DynamicForm } from './components/DynamicForm';
import { convertDateToInputFormat } from './Helpers/convertDateToInputFormat';

// Constantes para mensagens de sucesso e erro
const MESSAGES = {
  emailSuccess: 'E-mail enviado com sucesso',
  emailError: 'A operação falhou, tente novamente em breve',
  createSuccess: 'O protesto foi criado com sucesso',
  createError: 'Um erro ocorreu ao gerar o protesto. Por favor, tente novamente em breve.',
  editSuccess: 'O protesto foi editado com sucesso',
  editError: 'Um erro ocorreu ao editar o protesto. Por favor, tente novamente em breve.',
};

export const ProtestLettersPage = () => {
  // Permissions
  const hasPermission = permissions.vendemmiaProtestLetters;

  // Instances
  const [list, setList] = useState([]);
  const [protest, setProtest] = useState(null);
  const [process, setProcess] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [formattedSuppliers, setFormattedSuppliers] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const [identifier, setIdentifier] = useState();
  const [supplierIdentifiers, setSupplierIdentifiers] = useState();
  const [hasType, setHasType] = useState();
  const [documentPreview, setDocumentPreview] = useState();

  //triggers Modal
  const { isOpen: isOpenView, onOpen: onOpenView, onClose: onCloseView } = useDisclosure();

  //trigger Drawer
  const { isOpen: drawerEditIsOpen, onOpen: drawerEditOnOpen, onClose: drawerEditOnClose } = useDisclosure();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  const [disabledButtons, setDisabledButtons] = useState([]);

  // Load
  const load = (filters, _key, page) => {
    getProtestLetters(filters, page);
    getProtestSuppliers(filters, page);
    getProcessList();
  };

  // Custom hook for refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  // Requests
  const getProcessList = async (filters, page) => {
    const res = await executeRequest({
      action: () => requests.fetchProcessList(filters, page),
      setIsLoading,
    });
    setProcess(res.data);
  };

  const getProtestLetters = async (filters, page) => {
    const res = await executeRequest({
      action: () => requests.fetchProtestLetters(filters, page),
      setIsLoading,
    });
    setList(res.data.data);
  };

  const getProtestSuppliers = async (filters, page) => {
    const res = await executeRequest({
      action: () => requests.fetchProtestSuppliers(filters, page),
      setIsLoading,
    });
    setSuppliers(res.data.data);
  };

  const showDocument = async (index) => {
    onOpenView();
    setDocumentPreview(
      handleDocuments(`/open/process/protest/${identifier}/supplier/${supplierIdentifiers[index]}/view`, 'protestLetters')
    );
  };

  const sendEmail = async (index) => {
    await executeRequest({
      action: () => requests.sendProtestEmail(identifier, supplierIdentifiers[index]),
      msgSuccess: MESSAGES.emailSuccess,
      msgError: MESSAGES.emailError,
      callback: () => {
        // Desativa o botão após o envio bem-sucedido
        setDisabledButtons((prev) => [...prev, index]);
      },
    });
  };

  const createNewProcess = async (data) => {
    await executeRequest({
      action: () => requests.addProtestLetter(formattedDataAPI(data)),
      msgSuccess: MESSAGES.createSuccess,
      msgError: MESSAGES.createError,
      setIsLoading,
      triggerRefresh,
      callback: drawerEditOnClose,
    });
  };

  const editProcessLetter = async (identifier, data) => {
    await executeRequest({
      action: () => requests.editProtestLetterDetails(identifier, formattedDataAPI(data)),
      msgSuccess: MESSAGES.editSuccess,
      msgError: MESSAGES.editError,
      setIsLoading,
      triggerRefresh,
      callback: drawerEditOnClose,
    });
  };

  //formatar valores enviados para as APIs
  const formattedDataAPI = (data) => {
    // Extraindo os documentos dos fornecedores do formulário
    const supplierIdentifiers = data.suppliers
      .map((supplier) => {
        const { documentNumberFormatted } = supplier;

        // Encontrar o fornecedor correspondente na lista de fornecedores da API
        const matchedSupplier = suppliers.find((apiSupplier) => apiSupplier.documentNumberFormatted === documentNumberFormatted);

        // Retornar o identifier se houver um fornecedor correspondente
        return matchedSupplier ? matchedSupplier.identifier : null;
      })
      .filter(Boolean); // Remove valores nulos caso não encontre um fornecedor correspondente

    // Criando o objeto de dados final para envio à API
    const formattedData = {
      status: data.status || 'PENDENTE', // Usando o status do formulário ou um valor padrão
      date: data.date || '', // Usando a data do formulário ou um valor padrão
      suppliers: supplierIdentifiers, // Identificadores dos fornecedores
      process: process.find((item) => item.code === data.process)?.identifier || '', // Usando o código do processo ou um valor padrão
    };

    return formattedData;
  };

  // Actions
  const handleOpenEditModal = async (identifier) => {
    const res = await executeRequest({
      action: () => requests.getProtestLetterDetails(identifier),
      setIsLoading,
    });

    setDisabledButtons([]);
    setIdentifier(identifier);
    setProtest(res.data);
    // Atualize initialValues e abra o Drawer
    updateInitialValues(res.data);
    addParametersToURL({ type: 'edit' });
  };

  const updateInitialValues = (protestData) => {
    // Extraia os identificadores dos fornecedores associados ao processo
    const identifiersFromData = protestData.suppliers || [];
    setSupplierIdentifiers(identifiersFromData);

    // Use uma função de atualização do estado para garantir que o supplierIdentifiers seja atualizado corretamente
    setSupplierIdentifiers((prevIdentifiers) => {
      const newIdentifiers = protestData.suppliers || [];
      const updatedIdentifiers = [...prevIdentifiers, ...newIdentifiers]; // Mescla os identificadores anteriores e novos
      return updatedIdentifiers;
    });

    // Filtra a lista de fornecedores para incluir apenas os fornecedores relacionados ao processo
    const filteredSuppliers = suppliers.filter((supplier) => identifiersFromData.includes(supplier.identifier));

    // Mapeia os fornecedores para o formato necessário
    const mappedSuppliers = filteredSuppliers.map((supplier) => ({
      documentNumberFormatted: supplier.documentNumberFormatted || '',
      name: supplier.name || '',
      address: supplier.address || '',
      contacts: supplier.contacts || [],
    }));

    // Atualiza os valores iniciais do formulário combinando dados fixos do processo com os fornecedores
    const updatedValues = {
      status: protestData.status || 'PENDENTE',
      process: protestData.processCode || '',
      date: convertDateToInputFormat(protestData.dateFormatted, '/'),
      suppliers: mappedSuppliers,
    };

    // Não remover processos aqui; adicione todos os processos sem filtrar e adicione o processo atual no topo da lista
    const updatedProcessOptions = [
      { code: protestData.processCode, identifier: protestData.processIdentifier },
      ...process.filter((item) => item.code !== protestData.processCode), // Inclui os outros processos
    ];

    setIsDrawerOpen(false);
    setInitialValues(updatedValues);
    setFormattedSuppliers(filteredSuppliers);
    setProcess(updatedProcessOptions); // Defina todos os processos corretamente
    setIsDrawerOpen(true);
  };

  const resetInitialValues = () => {
    const updatedValues = {
      status: 'PENDENTE',
      process: '',
      date: '',
      suppliers: [],
    };
    setInitialValues(updatedValues);
    setIsDrawerOpen(true);
  };

  const onSubmitForm = (values) => {
    hasType === 'new' && createNewProcess(values);
    hasType === 'edit' && editProcessLetter(identifier, values);
  };

  const addNewProtest = () => {
    closeFloatActionButton();
    // Fechar o Drawer antes de abrir para novo registro
    if (drawerEditIsOpen) {
      drawerEditOnClose();
      setTimeout(() => {
        resetInitialValues();
        addParametersToURL({ type: 'new' });
      }, 300); // Pequeno atraso para garantir que o Drawer seja fechado completamente
    } else {
      resetInitialValues();
      addParametersToURL({ type: 'new' });
    }
  };

  // Component preset
  const customButtons = [
    {
      main: [{ label: 'Editar', tooltip: 'Editar protesto', icon: <MdOpenInNew size={20} />, action: handleOpenEditModal }],
      collapsed: [],
    },
  ];

  // Atualiza o hasType quando o parâmetro de URL muda
  useEffect(() => {
    const urlType = hasSpecificURLParameters(['type']).value;
    setHasType(urlType);
  }, [window.location.href]);

  useEffect(() => {
    if (initialValues) {
      // Só abre o Drawer se initialValues estiver definido
      drawerEditOnOpen();
    }
  }, [initialValues]);

  return (
    <Page
      screen="protestLetters"
      title="Cartas de protesto"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="processo, empresa ou ref"
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      forceLoadTrigger={forceLoadTrigger}
      showFilters={false}
      showPeriodFilter={false}
      FAB={[
        {
          title: 'Cadastrar consulta tributária',
          text: 'Cadastro de consulta tributária',
          action: (
            <Button
              leftIcon={<MdAdd color="#FFFFFF" size={20} />}
              _hover={{ bgColor: '#70D499' }}
              bg="green"
              p={'1.5rem 1.3rem'}
              borderRadius="50px"
              onClick={addNewProtest}>
              <Text mr="10px" color="white">
                Carta de protesto
              </Text>
            </Button>
          ),
          modality: 'custom',
        },
      ]}>
      <CommonList
        list={list}
        rawData={list}
        action={action}
        sorting={sorting}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
        handleSort={handleSort}
        customButtons={customButtons}
        isLoading={isLoading}
        {...commonListConfig}
      />
      <CommonDrawer
        placement="left"
        heading={hasType === 'new' ? 'Nova Carta de Protesto' : 'Editar Carta de Protesto'}
        initialValues={initialValues}
        isOpen={drawerEditIsOpen && isDrawerOpen}
        onOpen={drawerEditOnOpen}
        isLoading={isLoading}
        loadingText={'Salvando'}
        onClose={() => {
          drawerEditOnClose();
          setIsDrawerOpen(false); // Resetar o estado para garantir a abertura correta na próxima vez
        }}
        hasForm={true}
        callback={onSubmitForm}>
        <DynamicForm
          suppliers={formattedSuppliers}
          suppliersList={suppliers}
          processList={process}
          showDocument={showDocument}
          sendEmail={sendEmail}
          disabledButtons={disabledButtons}
          type={hasType}
        />
      </CommonDrawer>
      <CommonModal isOpen={isOpenView} onOpen={onOpenView} onClose={onCloseView} closeButton={true} fullpage={true}>
        {documentPreview}
      </CommonModal>
    </Page>
  );
};
