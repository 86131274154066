import { ErrorMessage, Field } from 'formik';

import { FormControl, FormLabel, Input, Text } from '@chakra-ui/react';

export const renderField = (name, placeholder, props) => (
  <FormControl>
    <FormLabel>{placeholder}</FormLabel>

    <Field name={name}>{({ field }) => <Input {...field} placeholder={placeholder} {...props} />}</Field>

    <ErrorMessage component="p" name={name}>
      {(msg) => <Text className="error-message-error">{msg}</Text>}
    </ErrorMessage>
  </FormControl>
);
