import React, { useEffect, useRef, useState } from 'react';

// import { FaTrashAlt } from 'react-icons/fa';
// import { MdOutlineModeEditOutline } from 'react-icons/md';

import { Button, Image, Box, Flex, Text, useDisclosure } from '@chakra-ui/react';

import add from '../../../assets/images/add.svg';
import Card from '../../../components/Card/Card';
import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
// import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import { filterOptionsVehicle } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';
import VehiclesCard from '../components/Cards/VehiclesCard';
import VehiclesTable from '../components/Tables/VehiclesTable';

// import { commonListConfig } from './components/commonListConfig';
import DynamicForm from './components/DynamicForm';
// import ModalCreateVehicle from './components/ModalCreateVehicle';

const yup = require('yup');

const VehicleManagementPage = () => { 
  const hasPermission = permissions.logisticFleetManagement;
  const request = useRef(0);

  const [isMobile, setIsMobile] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [filters, setFilters] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [list, setList] = useState([]);

  const { action, setAction, /*handleSort, sorting,*/ metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  const validationSchema = yup.object().shape({
    licensePlate: yup.string().required('Campo obrigatório'),
  });

  const load = (filters, key, page) => {
    setIsLoading(true);

    setFilters(filters);

    requests
      .listVehicles(filters, page)
      .then((res) => {
        if (request.current && request.current > key) {
          return;
        }
        request.current = key;

        setList(res.data);
        setMetadata(res.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  const exportListFABAction = async () => {
    //preset
    const endpoint = `/adm/vehicle/export-csv`;
    const fileName = `vehicles`;
    const extension = `csv`;
    const method = `GET`;
    const body = {};

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, filters);
  };

  const handleOpenModalNew = () => {
    setInitialValues({
      licensePlate: undefined,
      brand: undefined,
      color: undefined,
      typeCar: 'Caminhão',
      modelVehicle: undefined,
      ownership:  'VENDEMMIA',
      capacity:undefined, 
      yearManufacture: undefined,
      model: undefined,
      yearModel: undefined,
    });
    onOpen();
  };

  // const handleOpenModalEdit = (id, data) => {
  //   setInitialValues(data);
  //   onOpen();
  // };

  const handleVehicleNew = async (values) => {
    await executeRequest({
      action: () => requests.addVehicle(values),
      msgSuccess: 'Veículo criado com sucesso',
      msgError: 'Erro ao criar o veículo, tente novamente.',
      setIsLoading,
      triggerRefresh,
      callback: () => onClose()
    });
  };

  useEffect(() => {
    setFilterOptions(filterOptionsVehicle);
  }, []);

  //component preset
  // const subtitle = `Existe um total de ${metadata.total_count} perguntas veículos`;

  // Component preset
  // const customButtons = [
  //   {
  //     main: [
  //       { label: 'Editar', tooltip: 'Editar veículo', icon: <MdOutlineModeEditOutline size={20} />, action: handleOpenModalEdit },
  //       {
  //         label: 'Excluir',
  //         tooltip: 'Excluir veículo',
  //         icon: <FaTrashAlt size={16} color="#E74C3C" />,
  //         action: handleDelete,
  //         isButtonDisabled: (item) => !!(item?.qttTrips > 0),
  //       },
  //     ],
  //     collapsed: [],
  //   },
  // ];

  // 
  return (
    <Page
      screen="vehicle"
      title="Gestão de frota"
      breadcrumbs={[{ link: '#', title: 'Transporte' }]}
      textFilterPlaceholder="Placa e propriedade veículo"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      metadata={metadata}
      load={load}
      filterOptions={filterOptions}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      showFilters={true}
      showPeriodFilter={false}
      allowEmptySearchPeriod={true}
      // forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar veículo',
          text: 'Cadastro de veículo',
          action: (
            <Button variant="primary" pt="23px" pb="23px" borderRadius="50px" onClick={handleOpenModalNew}>
            <Image src={add} />
            <Text ml="10px">Novo veículo</Text>
          </Button>
          ),
          modality: 'custom',
        },
        {
          title: 'Exportar Veículos',
          text: 'Exportar arquivo CSV com dados de Veículos',
          action: exportListFABAction,
          modality: 'export-csv',
        },
      ]}>
      {/*<CommonList
        subtitle={subtitle}
        //lists
        list={list}
        rawData={list}
        //actions
        action={action}
        sorting={sorting}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
        handleSort={handleSort}
        deleteButtonAction={handleDelete}
        //custom
        customButtons={customButtons}
        //loading
        isLoading={isLoading}
        //props
        {...commonListConfig}
      />*/}
      <CommonDrawer
        heading="Dados do veículo"
        placement="left"
        hasForm={true}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        formik={{ initialValues, validationSchema }}
        callback={handleVehicleNew}>
        <DynamicForm values={initialValues} />
      </CommonDrawer>
      <Card
        m="10px"
        header={
          <Flex gap="2px" direction="column">
            <Text textStyle="cardTitle" fontWeight="bold" color="primary">
              Listagem de veículos
            </Text>

            <Text textStyle="subtitle" color="#BEBEBE">
              Existe um total de {metadata?.total_count} veículos
            </Text>
          </Flex>
        }>
        <Box p="15px">
          <ScreenLoader isLoading={isLoading}>
            {!isMobile ? (
              <VehiclesTable
                list={list}
                triggerRefresh={triggerRefresh}
                action={action}
                setAction={setAction}
                metadata={metadata}
                setMetadata={setMetadata}
                setIsLoading={setIsLoading}
              />
            ) : (
              <VehiclesCard
                list={list}
                triggerRefresh={triggerRefresh}
                action={action}
                setAction={setAction}
                metadata={metadata}
                setMetadata={setMetadata}
                setIsLoading={setIsLoading}
              />
            )}
          </ScreenLoader>
        </Box>
      </Card>
    </Page>
  );
};

export default VehicleManagementPage;
