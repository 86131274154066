import { useEffect, useState } from 'react';

import { MdAdd, MdOpenInNew } from 'react-icons/md';

import { Button, Text, useDisclosure } from '@chakra-ui/react';

import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import Page from '../../../components/Page';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import closeFloatActionButton from '../../../utils/actions/modal/closeFloatActionButton';
import { executeRequest } from '../../../utils/requests/executeRequest';
import addParametersToURL from '../../../utils/url/addParametersToURL ';
import hasSpecificURLParameters from '../../../utils/url/hasSpecificURLParameter';

import { commonListConfig } from './components/commonListConfig';
import { DynamicForm } from './components/DynamicForm';

// Constantes para mensagens de sucesso e erro
const MESSAGES = {
  createSuccess: 'O fornecedor foi criado com sucesso',
  createError: 'Um erro ocorreu ao gerar o fornecedor. Por favor, tente novamente em breve.',
  editSuccess: 'O fornecedor foi editado com sucesso',
  editError: 'Um erro ocorreu ao editar o fornecedor. Por favor, tente novamente em breve.',
};

export const SuppliersPage = () => {
  // Permissions
  const hasPermission = permissions.vendemmiaSuppliers;

  // Instances
  const [list, setList] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const [identifier, setIdentifier] = useState();
  const [hasType, setHasType] = useState();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  // Modal
  const { isOpen: drawerEditIsOpen, onOpen: drawerEditOnOpen, onClose: drawerEditOnClose } = useDisclosure();

  // Load
  const load = (filters, _key, page) => {
    getSuppliersList(filters, page);
  };

  // Custom hook for refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  // Requests
  const getSuppliersList = async (filters, page) => {
    const res = await executeRequest({
      action: () => requests.fetchProtestSuppliers(filters, page),
      setIsLoading,
    });
    setList(res.data.data);
  };

  const createNewProcess = async (data) => {
    await executeRequest({
      action: () => requests.addSupplier(data),
      msgSuccess: MESSAGES.createSuccess,
      msgError: MESSAGES.createError,
      setIsLoading,
      triggerRefresh,
      callback: drawerEditOnClose,
    });
  };

  const editProcessLetter = async (identifier, data) => {
    await executeRequest({
      action: () => requests.editSupplierDetails(identifier, data),
      msgSuccess: MESSAGES.editSuccess,
      msgError: MESSAGES.editError,
      setIsLoading,
      triggerRefresh,
    });
  };

  // Actions
  const handleOpenEditModal = async (identifier) => {
    const res = await executeRequest({
      action: () => requests.getSupplierDetails(identifier),
      setIsLoading,
    });
    setIdentifier(identifier);
    // Atualize initialValues e abra o Drawer
    updateInitialValues(res.data);
    addParametersToURL({ type: 'edit' });
  };

  const updateInitialValues = (suppliers) => {
    // Atualiza os valores iniciais do formulário combinando dados fixos do processo com os fornecedores
    const updatedValues = {
      name: suppliers.name || 'PENDENTE',
      documentNumber: suppliers.documentNumber || '',
      address: suppliers.address || '',
      contacts: suppliers.contacts || [],
    };

    setIsDrawerOpen(false);
    setInitialValues(updatedValues);
    setIsDrawerOpen(true);
  };

  const resetInitialValues = () => {
    const updatedValues = {
      name: '',
      documentNumber: '',
      address: '',
      contacts: [],
    };
    setInitialValues(updatedValues);
    setIsDrawerOpen(true);
  };

  const onSubmitForm = (values) => {
    hasType === 'new' && createNewProcess(values);
    hasType === 'edit' && editProcessLetter(identifier, values);
  };

  const addNewProtest = () => {
    closeFloatActionButton();
    // Fechar o Drawer antes de abrir para novo registro
    if (drawerEditIsOpen) {
      drawerEditOnClose();
      setTimeout(() => {
        resetInitialValues();
        addParametersToURL({ type: 'new' });
      }, 300); // Pequeno atraso para garantir que o Drawer seja fechado completamente
    } else {
      resetInitialValues();
      addParametersToURL({ type: 'new' });
    }
  };

  // Component preset
  const customButtons = [
    {
      main: [{ label: 'Editar', tooltip: 'Editar fornecedor', icon: <MdOpenInNew size={20} />, action: handleOpenEditModal }],
      collapsed: [],
    },
  ];

  // Atualiza o hasType quando o parâmetro de URL muda
  useEffect(() => {
    const urlType = hasSpecificURLParameters(['type']).value;
    setHasType(urlType);
  }, [window.location.href]);

  useEffect(() => {
    if (initialValues) {
      // Só abre o Drawer se initialValues estiver definido
      drawerEditOnOpen();
    }
  }, [initialValues]);

  return (
    <Page
      screen="suppliers"
      title="Fornecedores"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="cnpj, nome ou endereço"
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      forceLoadTrigger={forceLoadTrigger}
      showFilters={false}
      showPeriodFilter={false}
      FAB={[
        {
          title: 'Cadastrar consulta tributária',
          text: 'Cadastro de consulta tributária',
          action: (
            <Button
              leftIcon={<MdAdd color="#FFFFFF" size={20} />}
              _hover={{ bgColor: '#70D499' }}
              bg="green"
              p={'1.5rem 1.3rem'}
              borderRadius="50px"
              onClick={addNewProtest}>
              <Text mr="10px" color="white">
                Fornecedor
              </Text>
            </Button>
          ),
          modality: 'custom',
        },
      ]}>
      <CommonList
        list={list}
        rawData={list}
        action={action}
        sorting={sorting}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
        handleSort={handleSort}
        customButtons={customButtons}
        isLoading={isLoading}
        {...commonListConfig}
      />
      <CommonDrawer
        placement="left"
        heading={'Fornecedor'}
        initialValues={initialValues}
        isOpen={drawerEditIsOpen && isDrawerOpen}
        onOpen={drawerEditOnOpen}
        onClose={() => {
          drawerEditOnClose();
          setIsDrawerOpen(false); // Resetar o estado para garantir a abertura correta na próxima vez
        }}
        hasForm={true}
        callback={onSubmitForm}>
        <DynamicForm />
      </CommonDrawer>
    </Page>
  );
};
